@import 'instructions.css';
@import 'results.css';
@import 'keyboard.css';
@import 'modal.css';

/* Global styles */

*, *::before, *::after {
  font-family: Arial, sans-serif;
  box-sizing: border-box;

  --color-black: #121213;
  --color-dark-gray: #3a3a3c;
  --color-gray: #818384;
  --color-gold: #b59f3b;
  --color-green: #538d4e;
  --color-white: #fff;
}

html, body {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  background-color: var(--color-black);
  overflow-y: hidden;
}

button {
  cursor: pointer;
}

svg {
  vertical-align: middle;
}

::-webkit-scrollbar { /* Scrollbar Width */
  width: 5px;
}

::-webkit-scrollbar-thumb { /* Scrollbar Handle */
  background: var(--color-dark-gray);
}
 
::-webkit-scrollbar-track { /* Scrollbar Track */
  background: var(--color-black);
}

/* Top-level elements */

#root {
  height: calc(100% - 50px);
}

.header {
  height: 50px;
  padding: 5px;
  color: var(--color-white);
  font-size: 37px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid var(--color-dark-gray);
}

.header__button {
  background: transparent;
  border: 0;
  color: var(--color-white);
}
.header__button--settings {
  position: absolute;
  top: 10px;
  right: 5px;
}

.board {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.board__results {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
}

/* Shared classes */

.margin--0 {
  margin: 0;
}

.margin-top--20 {
  margin-top: 20px;
}

.margin-right--40 {
  margin-right: 40px;
}

.padding--10 {
  padding: 10px;
}

.bold {
  font-weight: bold;
}

.center {
  text-align: center;
}

.small {
  font-size: 0.8rem;
}

.medium {
  font-size: 1.3rem;
}

.background--excluded {
  background-color: var(--color-dark-gray);
}

.background--neutral {
  background-color: var(--color-gray);
}

.background--included {
  background-color: var(--color-gold);
}

.background--exact {
  background-color: var(--color-green);
}

.button__toggle--container {
  display: flex;
  justify-content: space-between;
}

.button__toggle {
  height: 20px;
  width: 32px;
  background: var(--color-gray);
  border: none;
  border-radius: 999px;
  display: block;
  position: relative;
}

.button__toggle--on {
  background: var(--color-green) !important;
}

.button__toggle--knob {
  display: block;
  position: absolute;
  left: 2px;
  top: 2px;
  height: calc(100% - 4px);
  width: 50%;
  border-radius: 8px;
  background: var(--color-white);
  transform: translateX(0);
  transition: transform 0.3s;
}

.button__toggle--on .button__toggle--knob {
  transform: translateX(calc(100% - 4px));
}

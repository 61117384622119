.instructions {
  width: 350px;
  height: calc(100vh - 270px);
  max-height: 420px;
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
  color: var(--color-white);
}

.instructions a {
  color: inherit;
  font-weight: bold;
  text-decoration: underline;
}

.instructions ol {
  padding: 0 0 0 20px;
  margin: 0;
}

.instructions ol li {
  margin-bottom: 20px;
}

.instructions ul {
  padding: 0 0 0 20px;
  margin: 0;
}

.instructions ul li {
  margin-bottom: 5px;
}

.instructions__span__padding {
  padding: 2px;
  border-radius: 3px;
  display: inline-block;
}

.instructions__button {
  font-weight: bold;
  border: 0;
  border-radius: 200px; /* This just makes it shaped like a pill */
  padding: 7px 0 7px 0;
  vertical-align: middle;
}

.instructions__button span {
  display: inline-block;
  margin-left: 10px;
}

.instructions__button--share {
  background-color: var(--color-green);
  color: white;
  width: 130px;
}

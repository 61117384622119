.results {
  width: 350px;
  height: calc(100vh - 260px);
  max-height: 420px;
  overflow-y: auto;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 5px;
  padding: 10px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */ 
  .results {
    height: calc(100vh - 320px);
  }
}

.results__row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 5px;
}

.results__row__letter {
  width: 100%;
  border: 0;
  margin: 0;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  line-height: 2em;
  font-weight: bold;
  vertical-align: middle;
  box-sizing: border-box;
  color: var(--color-white);
  text-transform: uppercase;
  user-select: none;
}